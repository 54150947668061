import { REQUEST_UNITS, RECEIVE_UNITS } from './unitsActions'

const initialState = {
  documents: [],
  isFetching: true
}

const units = apiUnits => apiUnits.map(apiUnit => {
  const unit = apiUnit.fields
  return {
    name: unit.name.stringValue,
    geopoint: unit.geopoint.geoPointValue,
    adress: unit.adress.stringValue,
    url: unit.url.stringValue,
    offer: unit.offer.stringValue
  }
})

export const unitsReducer = (state = initialState, action) => {
  const payload = action.payload

  switch (action.type) {
    case REQUEST_UNITS:
      return {
        ...state,
        isFetching: true
      }
    case RECEIVE_UNITS:
      return {
        ...state,
        documents: units(payload.data),
        isFetching: false
      }
    default:
      return state
  }
}
