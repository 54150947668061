import { requestUnits, receiveUnits } from './unitsActions'
import { getUnits } from '../firebase/unitsService'

export const loadUnits = () => (dispatch) => {
  dispatch(requestUnits())

  getUnits().then(
    response => dispatch(receiveUnits(response.documents))
  )
}
