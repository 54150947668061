import React from 'react'
import { Popup, Grid, Header, Icon } from 'semantic-ui-react'

import './Marker.css'

const Marker = (props) => {
    const { unit } = props

    return (
      <div>
        <Popup
          on='click'
          pinned
          trigger={<div className='pin bounce' style={{ backgroundColor: 'blue', cursor: 'pointer' }}/>}
        >
          <Grid centered>
            <Grid.Column>
              <Header as='h4'>
                <Icon name='food' />
                <Header.Content>
                  { unit.name }
                  <Header.Subheader>{ unit.adress }</Header.Subheader>
                </Header.Content>
              </Header>
              <p>
                <b>{ unit.offer }</b>
              </p>
              <a href={unit.url + '?utm_source=cheffle'} target='_blank' rel='noopener noreferrer'>Gå till erbjundandet.</a>
            </Grid.Column>
          </Grid>
        </Popup>
        <div className='pulse' />
      </div>
    )
  }

export default Marker
