import React, { useEffect, useState } from 'react'
import GoogleMapReact from 'google-map-react'
import { Grid, Input, Header, Divider, Loader, Message, Image, Responsive } from 'semantic-ui-react'
import { useDispatch, useSelector } from 'react-redux'

import { loadUnits } from '../redux/unitsActionCreators'
import UnitsList from '../components/UnitsList'
import Marker from '../components/Marker'

const stockholmProps = {
  center: {
    lat: 59.329323,
    lng: 18.068581
  },
  zoom: 13
}

function Main() {
  const dispatch = useDispatch()
  const unitsState = useSelector(state => state.units)
  const [searchQuery, setSearchQuery] = useState('')

  const units = () => {
    return unitsState.documents.filter(unit => {
      return unit.name
                 .toLowerCase()
                 .includes(searchQuery.toLowerCase())
    })
  }

  const markers = () => {
    return units().map(unit => (
      <Marker
        lat={unit.geopoint.latitude}
        lng={unit.geopoint.longitude}
        unit={unit}
        key={unit.name}
      />
    ))
  }

  useEffect(() => {
    dispatch(loadUnits())
  }, [])

  return (
    <Grid stackable>
      <Grid.Row columns={2}>
        <Grid.Column width={5} style={{ paddingLeft: '3em' }}>
          <br />

          <Grid.Row>
            <Header as='h1' style={{ fontSize: '1.7em' }}>
              <Image src='https://app.cheffle.se/assets/cheffle-logo-square.svg' size='large'/>
              Restauranger i Stockholm med Take Away/ Avhämtning och rabatter
            </Header>

              <Responsive {...Responsive.onlyMobile}>
                <Message positive style={{marginBottom: '10px'}}>
                  <b> Tips! Scrolla ner för att se en karta med alla erbjudanden! </b>
                </Message>
              </Responsive>

            <Input
              style={{border: '1px solid rgba(34,36,38,.35)', borderRadius: '.28571429rem'}}
              icon='search'
              iconPosition='left'
              fluid
              size='large'
              placeholder='Sök bland alla erbjudanden...'
              value={searchQuery}
              onChange={event => setSearchQuery(event.target.value)}
            />

            { unitsState.isFetching ? <Loader active/> : <UnitsList units={units()}/> }
          </Grid.Row>

          <Divider />

          <Grid.Row>
            <Message positive>
              <Message.Header>Vet du fler restauranger som erbjuder hämtmat i Corona-tider?</Message.Header>
              <p>
                Maila <a href='mailto:info@cheffle.se'>info@cheffle.se</a> så lägger vi till det!
              </p>
            </Message>
          </Grid.Row>
        </Grid.Column>

        <Grid.Column width={11} style={{ padding: '0'}}>
          <div style={{height: '100vh', width: '100%'}}>
            <GoogleMapReact
              bootstrapURLKeys={{ key: 'AIzaSyB0gas3kKL-RZzs9kg_9MFuI4xaVzJmj1E' }}
              defaultCenter={stockholmProps.center}
              defaultZoom={stockholmProps.zoom}
            >
              { markers() }
            </GoogleMapReact>
          </div>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns={1} centered>
        <Grid.Column width={5} style={{ paddingLeft: '3em' }}>
          <Header as='h1'>
            Hjälp oss att stötta dina favoritrestauranger!
          </Header>
          <p>
            Nu när Coronaviruset sprider sig över landet och Stockholm kan vi inte gå ut och äta på våra favoritrestauranger i samma utsträckning som tidigare. Men det går fortfarande att få tag i riktigt god, vällagad mat ändå! Många restauranger över hela Stockholm erbjuder Take away på ordinarie menyer, i en del fall också med en schysst rabatt. Stötta lokala favoritkrogen eller upptäck något nytt från soffan eller matbordet hemma.
          </p>
          <p>
            Här listar vi, per stadsdel, över 120 restauranger som erbjuder Take away/avhämtning i Stockholmsområdet. Listan uppdateras ständigt med nya restauranger & rabatter.
          </p>
          <p>
            City, Vasastan, Norrmalm, Kungsholmen, Söder, Östermalm, Djurgården.. You name it, vi har dom listade.
          </p>

          <Message positive icon>
            <Image src='https://app.cheffle.se/assets/cheffle-logo-square.svg' size='mini'/>
            <Message.Content style={{marginLeft: '10px'}}>
              <Message.Header as='h1'>Vill du veta mer om Cheffle?</Message.Header>
                <a href='https://cheffle.se/'>Besök oss på vår huvudsida!</a>
            </Message.Content>
          </Message>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  )
}


export default Main
