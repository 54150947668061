import React, { useEffect } from 'react'
import { List } from 'semantic-ui-react'

const UnitsList = ({ units }) => {

  useEffect(() => {
  }, [units])

  const listItems = () => {
    return units.map(unit => (
      <List.Item key={unit.name} style={{marginBottom: '5px'}}>
        <List.Icon name='food' color='blue'/>
        <List.Content>
          <List.Header as='h4' style={{marginBottom: '5px'}}>{ unit.name }</List.Header>
          <List.Description>
            { unit.offer }
          </List.Description>
        </List.Content>
      </List.Item>
    ))
  }

  return (
    <List divided style={{ maxHeight: '60vh', overflowY: 'auto'}}>
      { listItems() }
    </List>
  )
}

export default UnitsList
