import { applyMiddleware, combineReducers, createStore } from 'redux'
import thunk from 'redux-thunk'
import logger from 'redux-logger'

import { unitsReducer } from './unitsReducer'

const configureStore = () => {
  const reducer = combineReducers({
    units: unitsReducer
  })


  let middleWare = applyMiddleware(thunk, logger)

  if (process.env.NODE_ENV === 'production')
    middleWare = applyMiddleware(thunk)

  return createStore(reducer, middleWare)
}

export default configureStore
