export const REQUEST_UNITS = 'REQUEST_UNITS'
export const RECEIVE_UNITS = 'RECEIVE_UNITS'

export const requestUnits = () => {
  return {
    type: REQUEST_UNITS
  }
}

export const receiveUnits = units => {
  return {
    type: RECEIVE_UNITS,
    payload: {
      data: units
    }
  }
}
