import React from 'react'
import { Provider } from 'react-redux'

import Main from './views/Main'
import configureStore from './redux/store'

const store = configureStore()

function App() {
  return (
    <Provider store={store}>
      <Main />
    </Provider>
  );
}

export default App
